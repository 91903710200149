export const isAspectRatioClose = (ratio1, ratio2, tolerance) => {
  return Math.abs(ratio1 - ratio2) <= tolerance;
};

export const validateImage = (img, requiredAspectRatio, TOLERANCE) => {
  const width = img.width;
  const height = img.height;
  const aspectRatio = width / height;

  console.log("aspectRatio", aspectRatio);
  console.log("Requtied aspect ratio:", requiredAspectRatio);

  if (!isAspectRatioClose(aspectRatio, requiredAspectRatio, 0)) {
    return `The image you have uploaded does not meet the recommended size guidelines. Images that fall outside these specifications may not display correctly on mobile devices.`;
  }

  return null;
};
